@tailwind base;
@tailwind components;
@tailwind utilities;

.bm-cross-button{
    z-index: 1000;
    margin-top: 20px;
    margin-right: 10px;
    width: 32px;
    display: block;
    height: 32px;
    overflow: visible;
}

.player-wrapper {
    position: relative;
}
  
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
